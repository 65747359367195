import React, { useRef, useState, useEffect, useCallback } from 'react';
// Hooks
// import useEventListener from '../hooks/useEventListener';

function useVideoFullscreen() {

  const ref = useRef();
  const [isFullscreen, setFullscreen] = useState(false);

  const onFullscreenChange = event => {
    setFullscreen(o => !o) // toggle
  }

  useEffect(() => {
    const videoElement = ref.current;
    videoElement.addEventListener('webkitfullscreenchange', onFullscreenChange)
    videoElement.addEventListener('mozfullscreenchange', onFullscreenChange)
    videoElement.addEventListener('fullscreenchange', onFullscreenChange)
    videoElement.addEventListener('msfullscreenchange', onFullscreenChange)
    return () => {
      videoElement.removeEventListener('webkitfullscreenchange', onFullscreenChange, false)
      videoElement.removeEventListener('mozfullscreenchange', onFullscreenChange, false)
      videoElement.removeEventListener('fullscreenchange', onFullscreenChange, false)
      videoElement.removeEventListener('msfullscreenchange', onFullscreenChange, false)
    }
  }, [])

  return [ref, isFullscreen]
}

const Video = ({ className, src = "", poster = "", previewLoop = false, autoPlay = false, setCursorText }) => {

  const [element, isFullscreen] = useVideoFullscreen();
  // const [showClicker, setShowClicker] = useState(false);
  const [muted, setMuted] = useState(false);
  const [isLooping, setLooping] = useState(previewLoop);
  const [isTouch, setTouch] = useState(false);
  const [background, setBackground] = useState(false);

  useEffect(() => {
    if (window.matchMedia(`(hover: none)`).matches) {
      setTouch(true)
      // setBackground(true)
    }
  }, [])

  // const playVideo = () => {
  //   if (element.current.requestFullScreen) {
  // 		element.current.requestFullScreen();
  // 	} else if (element.current.webkitRequestFullScreen) {
  // 		element.current.webkitRequestFullScreen();
  // 	} else if (element.current.mozRequestFullScreen) {
  // 		element.current.mozRequestFullScreen();
  // 	}
  // }

  const playVideo = () => {
    setBackground(false)

    if (!element.current.paused) {
      element.current.pause()
      setCursorText("Play Showreel")
    } else {
      element.current.play()
      setCursorText("Pause Showreel")
    }
  }

  useEffect(useCallback(() => {
    if (isFullscreen) {
      setMuted(false)
      setLooping(false)
      element.current.currentTime = 0;
    } else {
      setMuted(true)
      setLooping(previewLoop)
    }
  }, [isFullscreen, setMuted, element]), [isFullscreen])

  const handleProgress = () => {
    if (isLooping && element.current.currentTime > 5) {
      element.current.currentTime = 0
    }
  }

	const handleMouseEnter = () => {
		if (element.current.paused) {
			setCursorText("Play Showreel")
		} else {
			setCursorText("Pause Showreel")
		}
	}

	const handleMouseLeave = () => {
		setCursorText("Click")
	}

  return (
    <video
      ref={element}
      className={background ? "bg" : ""}
      poster={poster}
      src={src}
      autoPlay={autoPlay}
      preload="auto"
      playsInline
      muted={false}
      loop={false}
      controls={isTouch}
      onClick={playVideo}
      onTouchEnd={playVideo}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
      onTimeUpdate={handleProgress}
    />
  )
}

export default Video
