// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// Hooks & Helpers
// ....
// Components
// ...

// MARKS
const Bold = ({ children }) => <strong>{children}</strong>
const Italic = ({ children }) => <i>{children}</i>

// BLOCKS
const P = ({ children }) => <p>{children}</p>
const H1 = ({ children }) => <h1>{children}</h1>
const H2 = ({ children }) => <h2>{children}</h2>
const H3 = ({ children }) => <h3>{children}</h3>
const H4 = ({ children }) => <h4>{children}</h4>

// [INLINES.HYPERLINK]
const Hyperlink = ({ data, children, handlers }) => {
	// console.log(data);
	return (
		<a href={data.uri} target="_blank" rel="noopener noreferrer" {...handlers}>{children}</a>
	)
}

// [INLINES.ASSET_HYPERLINK]
const DownloadLink = ({ data, children }) => {
	const file = data.target && data.target.file;
	return (
		file ? (
			<a href={file.url} download={file.title} target="_blank">{children}</a>
		) : (
			<span className="c-constraints">[Asset missing]</span>
		)
	)
}

// [INLINES.ENTRY_HYPERLINK]
const InlineEntry = ({ data, children }) => {
	
	const type = data && data.target && data.target.__typename

	if (!type) {
		return (
			<div className="debug">[INLINES.ENTRY_HYPERLINK] Missing data and/or __typename</div>
		)
	}

  if (type === 'ContentfulProject') {
		console.log(data.target)
    return (
			<div>{children}</div>
		)
  }
  
	return (
		<div className="debug">{type}</div>
	)
}

// [BLOCKS.EMBEDDED_ENTRY]
const EmbeddedEntry = ({ data }) => {

	const type = data && data.target && data.target.__typename

	if (!type) {
		return (
			<div className="debug">[BLOCKS.EMBEDDED_ENTRY] Missing data and/or __typename</div>
		)
	}

	if (type === 'ContentfulProject') {
		return (
			<div>Project component</div>
		)
	}

  return (
		<div className="debug">{type}</div>
	)
}

// const options = {
//   renderMark: {
//     [MARKS.BOLD]: text => <Bold>{text}</Bold>,
//     [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
//   },
//   renderNode: {
//     [BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
//     [BLOCKS.HEADING_1]: (_node, children) => <H1>{children}</H1>,
//     [BLOCKS.HEADING_2]: (_node, children) => <H2>{children}</H2>,
//     [BLOCKS.HEADING_3]: (_node, children) => <H3>{children}</H3>,
//     [BLOCKS.HEADING_4]: (_node, children) => <H4>{children}</H4>,
//     // [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
//     [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <EmbeddedEntry {...node}>{children}</EmbeddedEntry>,
//     // [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image {...node}>{children}</Image>,
// 		[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node}>{children}</Hyperlink>,
// 		[INLINES.ASSET_HYPERLINK]: (node, children) => <DownloadLink {...node}>{children}</DownloadLink>,
// 		[INLINES.ENTRY_HYPERLINK]: (node, children) => <InlineEntry {...node}>{children}</InlineEntry>
//   },
//   renderText: text => text.split('\n')
//     .reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
// }

const options = (linkHandlers) => {
	return {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: text => <Italic>{text}</Italic>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
			[BLOCKS.HEADING_1]: (_node, children) => <H1>{children}</H1>,
			[BLOCKS.HEADING_2]: (_node, children) => <H2>{children}</H2>,
			[BLOCKS.HEADING_3]: (_node, children) => <H3>{children}</H3>,
			[BLOCKS.HEADING_4]: (_node, children) => <H4>{children}</H4>,
			// [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
			[BLOCKS.EMBEDDED_ENTRY]: (node, children) => <EmbeddedEntry {...node}>{children}</EmbeddedEntry>,
			// [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image {...node}>{children}</Image>,
			[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node} handlers={linkHandlers}>{children}</Hyperlink>,
			[INLINES.ASSET_HYPERLINK]: (node, children) => <DownloadLink {...node}>{children}</DownloadLink>,
			[INLINES.ENTRY_HYPERLINK]: (node, children) => <InlineEntry {...node}>{children}</InlineEntry>
		},
		renderText: text => text.split('\n')
			.reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
	}
}

const RichText = ({ content, linkHandlers }) => (
	content && (
		renderRichText(content, options(linkHandlers))
	)
)

RichText.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default RichText

// export const ContentfulRichTextFragment = graphql`
// 	fragment ContentfulRichTextFragment on ContentfulRichTextNode {
// 		raw
// 		references {
// 			... on ContentfulAsset {
// 				__typename
// 				contentful_id
// 				title
// 				file {
// 					url
// 				}
// 				gatsbyImageData(
// 					layout: FULL_WIDTH
// 				)
// 			}
// 		}
// 	}
// `