// Dependencies
import React, { useState, useEffect } from "react"
// Hooks & Helpers
//...
// Components

const Section = ({ children, className, loadAnimation }) => {

  // not working
  const [sectionLoading, setSectionLoading] = useState(loadAnimation);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSectionLoading(false)
    }, 10);
    return () => clearTimeout(timer);
  }, [])

  return(
    <div className={`section vh100 ${sectionLoading ? "loading" : ""}`}>
      {children}
    </div>
  )
}

export default Section
