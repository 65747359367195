// Dependencies
import React, { useState, useEffect } from "react"
// Hooks & Helpers
//...
// Components

const Loop = ({ colourSchemes, children }) => {

  // UX
  const [listElements] = useState([...children, children[0]])
  const [colourScheme, setColourScheme] = useState({ background: colourSchemes[0].background, foreground: colourSchemes[0].foreground, highlight: colourSchemes[0].highlight })
  const [colourIndex, setColourIndex] = useState(1)

  useEffect(() => {
    document.documentElement.style.setProperty('--background', colourScheme.background)
    document.documentElement.style.setProperty('--foreground', colourScheme.foreground)
    document.documentElement.style.setProperty('--highlight', colourScheme.highlight)
  }, [colourScheme])

  useEffect(() => {
    const onScroll = e => {

      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        document.documentElement.scrollTop = 0

        if (colourIndex === (colourSchemes.length - 1)) {
          setColourIndex(0)
        } else {
          setColourIndex(colourIndex + 1)
        }
        setColourScheme({ background: colourSchemes[colourIndex].background, foreground: colourSchemes[colourIndex].foreground, highlight: colourSchemes[colourIndex].highlight })
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [listElements, colourScheme, colourSchemes, colourIndex]);

  return(
    <div>
      {listElements}
    </div>
  )
}

export default Loop
