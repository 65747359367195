// Dependencies
import React, { useEffect, useRef } from "react"
// Hooks & Helpers
import useMouse from "../hooks/useMouse"
// Components

const TextCursor = ({ content, visible, className, setClicked }) => {

	const ref = useRef()

	const { x, y } = useMouse()

  // const styles = {
  //   top: y,
  //   left: x
  // }

  // useEffect(() => {
  //   document.querySelector('.cursor').addEventListener('animationend', () => {
  //     setClicked(false)
  //   })
  // }, [className, setClicked])

	const handleAnimationEnd = () => {
		setClicked(false)
	}

  return(
    <div 
			className={`cursor pfix no-pointer ${className ? className : ""} ${visible ? "" : "hidden"}`} 
			style={{
				top: y,
				left: x
			}}
			onAnimationEnd={handleAnimationEnd}
		>
      {content}
    </div>
  )
}

export default TextCursor
