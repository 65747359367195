// Dependencies
import React, { useRef, useEffect, useState } from "react"
import lottie from "lottie-web"
// Hooks & Helpers
import useIntersection from "../hooks/useIntersection"
// Components

function useAnimation(ref, json) {

	const [animation, set] = useState(null)

	useEffect(() => {
		const init = lottie.loadAnimation({
			container: ref.current,
			renderer: 'svg',
			loop: false,
			autoplay: true,
			path: json
		})
		// Options...
		init.setSubframe(false)

		// Listeners..

		// Set state...
		set(init)
	}, [])

	const restart = () => {
		if (animation) {
			animation.goToAndPlay(0)
		}
	}

	const stop = () => {
		if (animation) {
			animation.goToAndStop(0)
		}
	}

	return [animation, restart]
}

const Animation = ({ json, clickPosition, isActive }) => {

  // Refs
  const animationRef = useRef()

  // UX
	const inViewport = useIntersection(animationRef)
	const [isVisible, setVisible] = useState(false)
  const [layer, setLayer] = useState(3)
  const [position, setPosition] = useState(clickPosition)

	// Animation
	const [animation, restartAnimation, stopAnimation] = useAnimation(animationRef, json)

	// Visibility
	useEffect(() => {
		if (isActive) {
			setVisible(true)
			restartAnimation()
      setLayer(Math.floor(Math.random() * 2) === 1 ? 3 : 5)
      setPosition(clickPosition)
		}
	}, [isActive])

  const styles = {
    top: position.y,
    left: position.x,
    zIndex: layer
  }

  useEffect(() => { // On complete
    animation && animation.addEventListener('complete', () => {
      setVisible(false)
    })
  }, [animation])

  useEffect(() => { // Outside viewport
    if (isVisible && !inViewport) {
      setVisible(false)
    }
  }, [inViewport])

  return(
    <div className={`animation ${isVisible ? "" : "hidden"}`} style={styles} >
      <div ref={animationRef} className="animation__inner" />
    </div>
  )
}

export default Animation
